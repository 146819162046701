import UI from  '../../../core/helpers/UI';
import ProfileService from '../services/ProfileService';
import File from "../../../core/helpers/File";

class ProfileController {
  context;
  profile_service;

  constructor(context) {
    this.context = context;
    this.profile_service = new ProfileService();

    if(this.context.$route.params.id) {
      this.context.contact_id = parseInt(this.context.$route.params.id);
    }
    
    this._infoProfile(this.context.contact_id);
    this._cardAddContact(this.context.contact_id);
  }

  _infoProfile(contact_id) {
    this.context.list_address = [];
    UI.loading('show');
    this.profile_service.infoProfile(contact_id)
    .then((r) => {
      if(r.success == true) {
        let first_name = r.data.pri_nombre != null && r.data.pri_nombre != 'null' ? r.data.pri_nombre + ' ' : '';
        let second_name = r.data.seg_nombre != null && r.data.seg_nombre != 'null' ? r.data.seg_nombre + ' ' : '';
        let first_surname = r.data.pri_apellido != null && r.data.pri_apellido != 'null' ? r.data.pri_apellido + ' ' : '';
        let second_surname = r.data.seg_apellido != null && r.data.seg_apellido != 'null' ? r.data.seg_apellido : '';

        this.context.contact_id = r.data.usuario_id;
        //this.context.photo = r.data.foto;

        const _photo = r.data.foto;
        this.context.photo = _photo;

        console.log('Finalasss this.context.photo:', this.context.photo);




        this.context.names = first_name + second_name;
        this.context.surnames = first_surname + second_surname;
        this.context.position = r.data.name_position;
        this.context.phone_number = r.data.telefono;
        this.context.address = r.data.address;
        this.context.email = r.data.email;
        this.context.website = r.data.website;
        this.context.facebook = r.data.facebook;
        this.context.instagram = r.data.instagram;
        this.context.twitter = r.data.twitter;
        this.context.linkedin = r.data.linkedin;
        this.context.skype = r.data.skype;
        console.log('Antes de asignar template:', this.context.template);
        this.context.template = r.data.code_template;
        console.log('Después de asignar template:', this.context.template);        
        if(r.data.qr_code && r.data.qr_code != null && r.data.qr_code != 'null') {
          this.context.qr_code = r.data.qr_code;
        }
        
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  openQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , true);
  }

  closeQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , false);
  }

  _cardAddContact(contact_id) {
    UI.loading('show');
    this.profile_service.cardAddContact(contact_id)
    .then((r) => {
      if(r.success == true) {
        this.context.card_contact = '';
        this.context.card_contact = r.data.link;
        
        UI.loading('hide');
      }
    }).catch((e) => {
      this.context.error = e;
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }
}

export default ProfileController;