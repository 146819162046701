import Rest from '../../../core/libs/Rest';
// import { COMPANY_MOCKUP } from '../mockups/Company';

class CompanyRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  infoCompany() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/public/show-company');
    const response = this.rest.get();
    console.log('Respuesta del repositorio:', response);
    return response;
  }
  
}

export default CompanyRepository;