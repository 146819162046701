import UI from  '../../../core/helpers/UI';
import File from "../../../core/helpers/File";
import CompanyService from '../services/CompanyService';

class CompanyController {
  context;
  company_service;

  constructor(context) {
    this.context = context;
    this.company_service = new CompanyService();

    if(this.context.$route.query.id) {
      this.context.contact_id = parseInt(this.context.$route.query.id);
    }
    if (!this.context.dialog_address) {
      this.context.dialog_address = [false];
    }
    
    this.infoCompany();
  }

  infoCompany() {
    this.context.list_address = [];
    UI.loading('show');
    this.company_service.infoCompany()
    .then((r) => {
      console.log('Respuesta en el controlador:', r);

      if(r.success == true) {
        this.context.scheduled = r.data.schedule;

        if(r.data.address) {
          console.log('Raw address:', r.data.address);

          let address = [];
          console.log('Raw address2:', r.data.address);

          address = JSON.parse(r.data.address);
          console.log('Parsed address:', address);

          this.context.list_address = address;
        } 
        
        if(r.data.image_logo) {
          this.context.photo_brand = File.fileUrlb(r.data.image_logo);
        }

        this.context.website = r.data.website;
        this.context.twitter = r.data.twitter;
        this.context.facebook = r.data.facebook;
        this.context.linkedin = r.data.linkedin;
        this.context.skype = r.data.skype;
        this.context.email = r.data.email;
        this.context.instagram = r.data.instagram

        this.context.url1  = r.data.url_boton1=='null'?'':r.data.url_boton1;
        this.context.url2  = r.data.url_boton2=='null'?'':r.data.url_boton2;
        this.context.url3  = r.data.url_boton3=='null'?'':r.data.url_boton3;
        this.context.descripcion1 = r.data.descripcion_boton1;
        this.context.descripcion2 = r.data.descripcion_boton2;
        this.context.descripcion3 = r.data.descripcion_boton3;
        //this.context.city = r.data.city;
        
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  openAddress() {
    console.log('Opening modal from controller');
    this.context.showAddressDialog = true;
  }

  closeAddress() {
    console.log('Closing modal from controller');
    this.context.showAddressDialog = false;
  }

  openGoogleMaps(google_maps_link) {
    if (google_maps_link) {
      window.open(google_maps_link, '_blank');
    }
  }
}

export default CompanyController;