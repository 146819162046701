<template>
  <div id="app" class="app-v3" data-app>
    <router-view></router-view>
  </div>
</template>

<script>
import Enviroment from '../helpers/Enviroment';
import Session from '../helpers/Session';

export default {
  mounted() {
    console.log("Componente montado");
    this.initialize();
  },


  methods: {
  initialize() {
    console.log("Inicializando...");
    
    let app = document.querySelector('#app');
    console.log("Elemento #app encontrado:", app);
    const logoComplete = require('@/core/assets/images/logo-login-alt.png');
    const logoLetter = require('@/core/assets/images/logo-login-min-alt.png');
    
    if (!Enviroment.isProyectOM()) {
      console.log("El proyecto es OM");

      // Definir los estilos en una variable
      const styles = `
        /* Color Top */
        .app-v1 .navbar { background-color: [color_top] !important; }            
        .app-v2 .navbar { background-color: [color_top] !important; }            
        .app-v3 .navbar { background-color: [color_top] !important; }
        .app-v3 .navbar .menu-header .navbar-nav .sub-menu-header .box-menu .sub-menu a, 
        .app-v3 .navbar .menu-header .navbar-nav .sub-menu-header .box-menu .sub-menu .text {
        
        }
        
        /* Color Menu */
        .app-v1 #nav-fixed { background-color: [color_menu] !important; }
        .app-v1 .nav-fixed .box-logo, .app-v1 .nav-fixed .bar-actions-nav { background-color: transparent !important; }
        .app-v2 #nav-fixed { background-color: [color_menu] !important; }
        .app-v2 .nav-fixed .box-logo, .app-v2 .nav-fixed .bar-actions-nav { background-color: transparent !important; }
        .app-v3 #nav-fixed { background-color: [color_menu] !important; }
        .app-v3 .nav-fixed .box-logo, .app-v3 .nav-fixed .bar-actions-nav { background-color: transparent !important; }
        .app-v3 .nav-fixed .box-logo img.complete {
            content: url(${logoComplete}) !important;
        }
        .app-v3 .nav-fixed .box-logo img.letter {
            content: url(${logoLetter}) !important;
        }

        /* Color Fondo */
        .app-v1 body { background-color: [color_fondo] !important; }
        .app-v2 section { background-color: [color_fondo] !important; }
        .app-v3 section { background-color: [color_fondo] !important; }
      `;

      // Crear un bloque de estilo
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = styles;

      // Agregar el bloque de estilo al DOM
      document.head.appendChild(styleSheet);
      console.log("Estilos agregados directamente al DOM");

    } else {
      console.log("El proyecto NO es OM");
    }
  }
}

};
</script>
