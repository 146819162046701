<template>
  <div>
    <!-- <div class="language-toggle d-flex justify-content-end mb-3">
      <button class="btn btn-primary mx-1" @click="toggleLanguage">{{ buttonText }}</button>
    </div> -->
  
  </div>
</template>

<script>
import TranslationService from '@/services/translationService';

export default {
  name: 'CustomMenu',
  data() {
    return {
      currentLanguage: TranslationService.getCurrentLocale(), // Obtiene el idioma inicial
    };
  },
  computed: {
    buttonText() {
      return this.currentLanguage === 'en' ? 'ENGLISH' : 'ESPAÑOL';
    },
  },
  methods: {
    toggleLanguage() {
      this.currentLanguage = this.currentLanguage === 'en' ? 'en' : 'en';
      TranslationService.setLocale(this.currentLanguage);
      this.$emit('languageChanged', this.currentLanguage); // Emitir evento si es necesario
    },
  },
};
</script>

<style>
.language-toggle {
  margin-bottom: 20px;
}
</style>
