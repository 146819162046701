<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>{{ translate('breadcrumb.home') }}</li>
            <li>{{ translate('breadcrumb.users') }}</li>
            <li>{{ is_edit ? translate('breadcrumb.update') : translate('breadcrumb.create') }}</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">{{ is_edit ? translate('title.updateUser') : translate('title.createUser') }}</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <v-form class="w-100" ref="form_user" method="post" v-model="form_valid" lazy-validation @submit.prevent="manageUser()">
              <div class="row">
                <div class="col-md-6">
                  <v-file-input
                  filled
                  :label="translate('form.profilePhoto')"
                  :prepend-icon="null"
                  @change="uploadPhoto($event)"
                  v-model="upload_photo"
                  v-if="!photo"
                ></v-file-input>
                  <div class="row pb-4" v-if="photo">
                    <div class="col-sm-8">
                      <div v-if="photo">
                        <img :src="File.fileUrl(photo)" class="d-block" width="100%" alt="">
                      </div>                    </div>
                    <div class="col-sm-4">
                      <v-btn color="danger" @click="deletePhoto()" width="100%">
                        {{ translate('button.deletePhoto') }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.firstName')"
                    v-model="first_name"
                    :rules="[rules.requerido]"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.secondName')"
                    v-model="second_name"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.firstSurname')"
                    v-model="first_surname"
                    :rules="[rules.requerido]"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.secondSurname')"
                    v-model="second_surname"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-select
                    filled
                    :label="translate('form.documentType')"
                    :items="type_id_list"
                    item-text="name"
                    item-value="type_id"
                    v-model="type_id"
                    :rules="[rules.requerido]"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.identityNumber')"
                    type="number"
                    v-model="number_id"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-select
                    filled
                    :label="translate('form.userType')"
                    :items="type_user_list"
                    item-text="nombre"
                    item-value="tipo_id"
                    v-model="type_user"
                    :rules="[rules.requerido]"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.email')"
                    type="email"
                    v-model="email"
                    :rules="[rules.email, rules.requerido]"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-select
                    filled
                    :label="translate('form.position')"
                    :items="position_list"
                    item-text="name"
                    item-value="position_id"
                    v-model="position"
                    :rules="[rules.requerido]"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.address')"
                    type="text"
                    v-model="address"
                    :rules="[rules.address, rules.requerido]"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.phone')"
                    type="tel"
                    v-model="phone_number"
                    :rules="[rules.phone_number, rules.requerido]"
                  ></v-text-field>
                </div>
                <div class="col-md-6" v-if="!is_edit">
                  <small class="text-muted d-block mt-1" style="margin-top: -10px;">
                    {{ translate('form.minChars') }}
                  </small>

                  <v-text-field
                    filled
                    :label="translate('form.password')"
                    :append-icon="!show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_pass ? 'text' : 'password'"
                    @click:append="show_pass = !show_pass"
                    v-model="password"
                    :rules="[rules.requerido]"
                  ></v-text-field>
                </div>
                
                <div class="col-md-6" v-if="!is_edit">
                  <small class="text-muted d-block mt-1" style="margin-top: -10px;">
                    {{ translate('form.minChars') }}
                  </small>

                  <v-text-field
                  filled
                  :label="translate('form.confirmPassword')"
                  :append-icon="!show_re_pass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_re_pass ? 'text' : 'password'"
                  @click:append="show_re_pass = !show_re_pass"
                  v-model="re_password"
                  :rules="[rules.requerido]"
                ></v-text-field>
                </div>
                
                <div class="col-md-6">
                  <!-- <v-text-field filled label="Facebook" type="url" v-model="facebook" :rules="[rules.requerido]"></v-text-field> -->
                  <v-text-field filled label="Facebook" type="url" v-model="facebook"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Instagram" type="url" v-model="instagram"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Twitter" type="url" v-model="twitter"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Linkedin" type="url" v-model="linkedin"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Skype" type="url" v-model="skype"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.website')"
                    v-model="website"
                    type="url"
                  ></v-text-field>
                  <small class="text-muted d-block mt-1" style="margin-top: -10px;">
                    {{ translate('form.passwordRequirements') }}
                    <ul class="m-0 p-0" style="list-style-type: none;">
                      <li>- {{ translate('form.requirement.uppercase') }}</li>
                      <li>- {{ translate('form.requirement.lowercase') }}</li>
                      <li>- {{ translate('form.requirement.number') }}</li>
                      <li>- {{ translate('form.requirement.specialChar') }}</li>
                    </ul>
                  </small>
                </div>
                
                <div class="col-md-6">
                  <v-select
                    filled
                    :label="translate('form.template')"
                    :items="list_templates"
                    item-text="name"
                    item-value="template_id"
                    v-model="template"
                    :rules="[rules.requerido]"
                  ></v-select>
                </div>
                
                <v-btn
                  type="submit"
                  rounded
                  color="success"
                  min-width="150"
                  class="d-table mx-auto mt-3"
                >
                  {{ is_edit ? translate('button.update') : translate('button.save') }}
                </v-btn>
             
                
            </v-form>
          </v-card-text>
        </v-card>

        <v-card class="mt-3 mt-md-4" v-if="is_edit">
          <v-card-title class="px-md-4 pb-0 fw-700">
            {{ translate('title.updatePassword') }}
          </v-card-title>
          <v-card-text class="pb-0 px-md-4 pt-md-4">
            <v-form class="w-100" ref="form_password" @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-6">
                      <v-text-field
                        id="password"
                        filled
                        :label="translate('form.password')"
                        :append-icon="!show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_pass ? 'text' : 'password'"
                        @click:append="show_pass = !show_pass"
                        v-model="password"
                        :rules="[rules.requerido, rules.passwordStrength]"
                        :error-messages="passwordErrors"
                        aria-describedby="password-description"
                      ></v-text-field>
                      <div id="password-description" class="mt-2" style="font-size: 14px; color: #6c757d;">
                        {{ translate('form.passwordRequirements') }}
                        <ul class="m-0 p-0" style="list-style-type: none;">
                          <li>- {{ translate('form.requirement.uppercase') }}</li>
                          <li>- {{ translate('form.requirement.lowercase') }}</li>
                          <li>- {{ translate('form.requirement.number') }}</li>
                          <li>- {{ translate('form.requirement.specialChar') }}</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <v-text-field
                        id="re_password"
                        filled
                        :label="translate('form.confirmPassword')"
                        :append-icon="!show_re_pass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_re_pass ? 'text' : 'password'"
                        @click:append="show_re_pass = !show_re_pass"
                        v-model="re_password"
                        :rules="[rules.requerido, rules.passwordMatch]"
                        :error-messages="rePasswordErrors"
                        aria-describedby="confirm-password-description"
                      ></v-text-field>
                      <div id="confirm-password-description" class="visually-hidden">
                        {{ translate('form.confirmPasswordHint') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <v-btn color="success" @click="onSubmit" min-height="56" width="100%">
                    {{ translate('button.save') }}
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
        
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from "../../../core/helpers/File";
import ManageController from '../controllers/ManageController';
import ManageService from '../services/ManageService';
import TranslationService from "@/services/translationService";


export default {
  manageService: new ManageService(),

  name: 'UserManage',
  data() {
    return {
      Helpers,
      File,

      first_name: '',
      second_name: '',
      first_surname: '',
      second_surname: '',
      type_id: '',
      number_id: '',
      type_user: '',
      email: '',
      position: '',
      password: '',
      show_pass: false,
      re_password: '',
      show_re_pass: false,
      upload_photo: null,
      //photo: '8ae0206c489ce5-tk-_sl_Fcontacts-profile_sl_F2022_sl_F07_sl_F26_sl_F1658874230_om_Cat%25C3%25A1logo-Blackday-Store.jpg',
      photo: '',
      template: '',
      show_card: '1',
      website: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      skype: '',
      phone_number: '',
      address: '',

      type_id_list: [],
      type_user_list: [],
      position_list: [],
      list_templates: [],
      list_created_card: [],

      user_id: '',
      is_edit: false,
      
      form_valid: true,
      rules: {
        requerido: v => !!v || 'Este campo es requerido',
        passwordStrength: v => (v && v.length >= 8) || 'La contraseña debe tener al menos 8 caracteres',
        passwordMatch: () => this.password === this.re_password || 'Las contraseñas no coinciden'
      },

      manage_controller: null
    };
  },

  computed: {
     photoUrl() {
      return this.photo ? File.fileUrl(this.photo) : '';
    },
    passwordErrors() {
      return this.getFieldErrors('contrasenia');
    },
    rePasswordErrors() {
      return this.getFieldErrors('confirm_password');
    }
  },
  mounted() {
    this.manage_controller = new ManageController(this);
    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Evento localeChanged recibido en Manage.vue");
      this.$forceUpdate(); // Fuerza la actualización del componente
    });
  },

  methods: {

    translate(key) {
      try {
        const translation = TranslationService.t(key);
        console.log(`Traducción para ${key}: ${translation}`); // Verifica las traducciones
        return translation;
      } catch (error) {
        console.error(`Error al traducir la clave: ${key}`, error);
        return key; // Devuelve la clave como texto por defecto
      }
    },
    uploadPhoto(file) {
  const baseUrl = ''; // Leer la variable de entorno

  if (file) {
    File.uploadFile(file, '/users', (response) => {
      if (response && response.ruta_completa) {
        // Quitar el primer '/' y eliminar 'zfiles/archivos/users/' de la ruta completa
        const rutaProcesada = response.ruta_completa
          .replace(/^\//, '') // Quita el primer '/'
          .replace('zfiles/archivos/users/', ''); // Quita 'zfiles/archivos/users/'

        const fullUrl = `${baseUrl}${rutaProcesada}`;
        this.photo = fullUrl;
        console.log("URL procesada de la imagen:", this.photo);
      } else {
        console.warn("No se recibió 'ruta_completa' en la respuesta:", response);
      }
    });
  }
}


,
  

    deletePhoto() {
      this.manage_controller.deletePhoto();
    },

    manageUser() {
      this.manage_controller.manageUser();
    },
    onSubmit() {
      console.log('1. onSubmit llamado');
      console.log('password:', this.password);
      console.log('re_password:', this.re_password);
      
      if (this.$refs.form_password.validate()) {
        console.log('2. Formulario validado, llamando a managePassword');
        this.manage_controller.managePassword();
      } else {
        console.log('2. Validación del formulario falló');
        UI.mensajeBad('', 'Por favor, corrige los errores en el formulario.');
      }
    },
    getFieldErrors(fieldName) {
      if (this.$refs.form_password && this.$refs.form_password.inputs) {
        const field = this.$refs.form_password.inputs.find(input => input.id === fieldName);
        return field ? field.errorBucket : [];
      }
      return [];
    },
  
async managePassword() {
      console.log('Intentando actualizar contraseña');
      try {
        const response = await this.manage_controller.managePassword(this.contrasenia, this.re_password);
        if (response.success) {
          this.showSnackbar('Contraseña actualizada exitosamente.', 'success');
          this.resetForm();
        } else {
          this.showSnackbar(response.message || 'Error al actualizar la contraseña.', 'error');
        }
      } catch (error) {
        console.error('Error en managePassword:', error);
        this.showSnackbar('Error en la conexión. Por favor, intenta de nuevo.', 'error');
      }
    },
    showSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    resetForm() {
      this.password = '';
      this.re_password = '';
      if (this.$refs.form_password) {
        this.$refs.form_password.reset();
      }
    }
  }
}
</script>